import { default as React } from "react"

const App = ({ name, imageName, isInBeta }) => {
  return (
    <div className="App">
      <img src={imageName} style={{ width: "50px", height: "50px" }}></img>
      <h1>{name}</h1>
      <h2>Privacy Policy</h2>
      <p>
        We do not collect, record, or share any information from your usage of{" "}
        {name}. Your information stays entirely on your device and within secure
        iCloud folders only accessible by you on your devices.
      </p>
      {isInBeta && <p>This app is currently in beta.</p>}
    </div>
  )
}

export default App
