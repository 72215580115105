import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import App from "../components/app"

import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="DetailsPro" />
    <div className="BasicPage">
      <App name="DetailsPro" imageName="/icon-detailspro.jpg" isInBeta={true} />
    </div>
  </Layout>
)

export default Page
